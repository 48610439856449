const axios = require('axios');
const timeout =15000;
function fetch_timeout(url, options = {}) {
    /*
     * fetches a request like the normal fetch function 
     * but with a timeout argument added.
     */
    let timeout = options.timeout || timeout;
    let timeout_err = {
        ok: false,
        status: 408
    };
    return new Promise(function(resolve, reject) {
        fetch(url, options).then(resolve, reject);
        setTimeout(reject.bind(null, timeout_err), timeout);
    });
}
const headerContent={
    'content-type': 'application/json',
    'accept': 'application/json'
}
module.exports.post = function(url,data, callback) {
    axios({
        method: 'post',
        url: url,
        data:data,
        headers: headerContent,
        timeout: timeout,
    }).then((response) =>{
        callback(null, response.data)
    }
    ).catch(function (error) {
        callback(error, null);
    });
}
module.exports.get = function(url,timeout, callback) {
    // axios({
    //     method: 'get',
    //     url: url,
    //     headers: headerContent,
    //     timeout: timeout,
    // }).then((response) =>{
    //     callback(null, response.data)
    // }
    // ).catch(function (error) {
    //     callback(error, null);
    // });
    fetch_timeout(url, {timeout: timeout}).then(response => 
        response.text()
    )
    .then((responseText) => { 
        callback(null, JSON.parse(responseText))
    })
    .catch(error => {
        callback(error, null)
    })
}