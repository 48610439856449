
import React, { useState, useEffect, useRef, useContext } from "react";
import { SocketProvider, SocketContext } from "./socketContext";
import io from "socket.io-client";
import moment from "moment";
import Word from './_data.json'
import APIUtils from './APIUtils'
function App() {
  const [opacity, setOpacity] = useState(1);
  const [data, setData] = useState([]);
  const [liveData, setLiveData] = useState(null);
  useEffect(() => {
    getData()
    const socket = io("http://209.97.169.178:3001", {});
    socket.on("live_price", (live_price) => {
      //console.log("live_price ", live_price);
      setLiveData(live_price);
      if (live_price?.playAnimation) {
        setAnimation(0);
      }
    });

    return () => {
      console.log("app close");
      // socket.close();
    };
  }, []);
  const getData=()=> {
    let last31date = moment().subtract(35, 'day');
    APIUtils.get(`http://209.97.169.178:3001/get_all_history`, 30000, (err, response) => {
      if (err) {
        console.log(err);
        // setData([]);
        // setFinishData([]);
        // setCurrentData(null);
      } else {
        if (response && response.data) {
          let finished = response.data.filter(x => x.finish == true&moment(x.date,'DD-MM-YYYY').diff(last31date)>0);
          let data = finished.map((item, index) => {
            item.group = item?.date;
            return item;
          });
          let group_data = data.reduce(function (r, a) {
            r[a.group] = r[a.group] || [];
            r[a.group].push(a);
            return r;
          }, Object.create(null));
          setData(Object.entries(group_data))
        } else {
          // setData([]);
          // setFinishData([]);
          // setCurrentData(null);
        }
      }
    });
  }
  const setAnimation = (amorpm) => {
    setOpacity(0);
    setTimeout(() => {
      setOpacity(1);
    }, 400);
  };
  const renderCard=(label,btc,eth,wn)=>{
    return(
      <div className={`${cardStyle} bg-slate-200 mb-3`}>
                <p className="text-[1.2em] font-bold txtShadow"> {label}</p>
                <div className="h-[20%] flex flex-row justify-around px-2 items-center">
                  <div className="text-md font-medium flex flex-col items-center justify-center flex-1 ">
                    <div>BTC</div>
                    <div className="text-[#939292]">
                      {isNaN(btc) ? btc : btc.toString().substring(0, 8)}
                    </div>
                  </div>
                  <div className="text-md font-medium flex flex-col items-center justify-center flex-1">
                    <div>ETH</div>
                    <div className="text-[#939292]">
                      {isNaN(eth) ? eth : eth.toString().substring(0, 7)}
                    </div>
                  </div>
                  <div className="text-md font-medium flex flex-col items-center justify-center flex-1">
                    <div>2D</div>
                    <div className="text-amber-500">
                      {wn}
                    </div>
                  </div>
                </div>
                <div className="h-[20%] flex flex-row justify-center items-center my-3">
                  {getMark(wn).map((mark,__index)=>(
                    
                    <div className={`text-sm font-medium flex flex-col items-center justify-center bg-grey-600 text-white px-4 bg-amber-500 py-2 rounded-3xl mx-2`}>
                      {mark}
                    </div>
                        ))}
                  </div>
              </div>
    )
  }
  const getRandomColor=() =>{
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`
  }
  const getMark=(num)=> {
    let temp = [];
    let sp = Word.sp.filter(x => x == num);
    if (sp.length > 0) {
      temp.push('စုံပူး');
    }
    let mp = Word.mp.filter(x => x == num);
    if (mp.length > 0) {
      temp.push('မပူး');
    }
    let ss = Word.ss.filter(x => x == num);
    if (ss.length > 0) {
      temp.push('စုံစုံ');
    }
    let mm = Word.mm.filter(x => x == num);
    if (mm.length > 0) {
      temp.push('မမ');
    }
    let sm = Word.sm.filter(x => x == num);
    if (sm.length > 0) {
      temp.push('စုံမ');
    }
    let ms = Word.ms.filter(x => x == num);
    if (ms.length > 0) {
      temp.push('မစုံ');
    }
    let nk = Word.nk.filter(x => x == num);
    if (nk.length > 0) {
      temp.push('ညီကို');
    }
    let k = Word.k.filter(x => x == num);
    if (k.length > 0) {
      temp.push('နက္ခတ်');
    }
    let w = Word.w.filter(x => x == num);
    if (w.length > 0) {
      temp.push('ပါဝါ');
    }
    let b = (Number(num.charAt(0)) + Number(num.charAt(1)))
      .toString()
    temp.push(`${b.length>1?b.charAt(1):b}ဘရိတ်`);
    return temp;
  }
  const cardStyle = "rounded-md shadow-md text-center w-2/3 px-1 py-1";
  return (
    <div className="grid grid-cols-2 h-[100vh] overflow-y-hidden no-scrollbar">
      {/* column 1 */}
      <div className="h-[100vh]">
        {/* Number */}
        <div className="h-[30%] text-slate-900 flex items-center justify-center">
          {
            opacity==0?null:<p className="text-[10em] font-bold text-shadow-md text-amber-500">{liveData?.w2}</p>
          }
          
        </div>
        <div className="h-[20%] flex flex-row justify-around px-8 items-center">
          <div className="text-2xl font-medium flex flex-col items-center justify-center gap-2">
            <div>Bitcoin (BTC)</div>
            {
              opacity==0?null:
              <div className="text-[#939292]">
              {liveData ? liveData?.s.toString().substring(0, 8) : liveData?.s}
            </div>
            }
            
          </div>
          <div className="text-2xl font-medium flex flex-col items-center justify-center gap-2">
            <div>Ethereum (ETH)</div>
            {
              opacity==0?null:
              <div className="text-[#939292]">
              {liveData ? liveData.v.toString().substring(0, 7) : liveData?.v}
            </div>
            }
            
          </div>
        </div>
        <div className="text-center w-full py-4 text-[#939292]">
          {liveData?.showArrow?`Updated : `:`Updating : `}
          {`${
            liveData?.showArrow
              ? moment
                  .utc(liveData?.dt)
                  .format("DD MMM HH:mm:ss")
                  .indexOf(":45") != -1
                ? moment
                    .utc(liveData?.dt)
                    .add(15, "seconds")
                    .format("DD MMM HH:mm:ss")
                : moment.utc(liveData?.dt).format("DD MMM HH:mm:ss")
              : moment.utc(liveData?.dt).format("DD MMM HH:mm:ss")
          }`}
        </div>
        <div className="h-[40%] space-y-8 flex flex-col items-center justify-center">
          {liveData?.d.map((v, i) => {
            return (
              <div className={`${cardStyle} bg-slate-200`}>
                <p className="text-[1.2em] font-bold txtShadow mb-5"> {v?.l}</p>
                <div className="h-[20%] flex flex-row justify-around px-2 items-center">
                  <div className="text-md font-medium flex flex-col items-center justify-center flex-1 ">
                    <div>BTC</div>
                    <div className="text-[#939292]">
                      {isNaN(v?.s) ? v?.s : v.s.toString().substring(0, 8)}
                    </div>
                  </div>
                  <div className="text-md font-medium flex flex-col items-center justify-center flex-1">
                    <div>ETH</div>
                    <div className="text-[#939292]">
                      {isNaN(v?.v) ? v?.v : v.v.toString().substring(0, 7)}
                    </div>
                  </div>
                  <div className="text-md font-medium flex flex-col items-center justify-center flex-1">
                    <div>2D</div>
                    <div className="text-amber-500">
                      {v?.w2}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* column 2 */}
      {/* animate-loop-scroll no-scrollbar */}
      <div className="space-y-8 py-2 animate-loop-scroll no-scrollbar">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-evenly"
          >
            <p className="text-[1.4em] font-bold text-shadow-md text-teal-600 txtShadow mb-3">{item[0]}</p>
            {item[1].reverse().map((v, i) => (
                  <>
                  {renderCard(v?.label,v?.btcValue,v?.etcValue,v?.winNum)}
                  
                  </>
            ))}
                
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
